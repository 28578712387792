document.querySelectorAll(".section-select").forEach(select => select.addEventListener("click", function (event) {
  event.preventDefault();
  selectSection(event.target.dataset.section);
}));

const msPerYear = 1000*60*60*24*365.25;
document.addEventListener('DOMContentLoaded',function(event){
  document.querySelectorAll(".time").forEach(span => {
    const date = new Date(span.dataset.time);
    span.innerText = Math.round((Date.now() - date) / msPerYear);
  });
});

function selectSection(name) {
  const oldSection = document.querySelector(".current-section");
  const section = document.querySelector("." + name);
  if (oldSection === section) return;
  if (oldSection != null) {
    oldSection.classList.remove("current-section");
    oldSection.classList.add("outscreen-" + oldSection.dataset.direction);
    setTimeout(function () {
      oldSection.classList.add("hidden");
    }, 750);
  }
  section.classList.remove("hidden");

  setTimeout(function () {
    section.classList.remove("outscreen-right");
    section.classList.remove("outscreen-left");
    section.classList.remove("outscreen-bottom");
    section.classList.add("current-section");
  }, 5);

  const oldLink = document.querySelector(".current-link");
  const link = document.querySelector("#" + name + "-select");
  if (link != null) {
    oldLink.classList.remove("current-link");
    link.classList.add("current-link");
  }
}
